<template>
  <div class="container">

    <h3>
      Perfil de Usuario: <strong>{{ currentUser.username }}</strong>
    </h3>

    <p>
      <strong>Database ID:</strong>
      {{ currentUser.id }}
    </p>
    <p>
      <strong>Email:</strong>
      {{ currentUser.email }}
    </p>
    <strong>Nivel de acceso:</strong>
    <ul>
      <li v-if="currentUser.roles[0] === 'ROLE_ADMIN'">Administrador</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
};
</script>